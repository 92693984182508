/* ============================== Start of Reset ============================= */
:root {
    --blue: #5e72e4;
    --indigo: #5603ad;
    --purple: #8965e0;
    --pink: #f3a4b5;
    --red: #f5365c;
    --orange: #fb6340;
    --yellow: #ffd600;
    --green: #2dce89;
    --teal: #11cdef;
    --cyan: #2bffc6;
    --white: #fff;
    --gray: #8898aa;
    --gray-dark: #32325d;
    --light: #ced4da;
    --lighter: #e9ecef;
    --dark: #212529;
    --darker: black;
    --neutral: #fff;
    --star-color: #FF7A00;
    
    --primary: #8BC34A;
    --primary-light: #9652b8;

    --secondary-dark: #FFE600;
    --secondary: #FFE600;
    --secondary-light: #fff063;

    --basic: #172b4d;
    --default: #172b4d;
    --success: #2dce89;
    --info: #11cdef;
    --warning: #fb6340;
    --danger: #f5365c;
    
    --background:white;
    --surface: #fff;
    --on-primary: #fff;
    --on-secondary: #000;
    --on-background: #000;
    --on-surface: #525f7f;
}

@font-face {
  font-family: Cocogoose;
  src: url(../fonts/Cocogoose/CocogooseProLight-trial.ttf);
}

.btn-star{
    background-color: var(--star-color);
  }

.btn-primary{
   background-color: var(--primary);
}

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


html, body{
    /* font-family: 'Roboto', Arial, Helvetica, sans-serif; */
    background: var(--background);
    color: var(--on-surface);
    font-family: 'Poppins', sans-serif;
    font-size: 87.5%;
    font-weight: 400;
    line-height: 1.5;
    overflow-x: hidden;
}

.clearfix{
    overflow: hidden;
    display: block;
}

.font-sans{
    font-family:sans-serif;
}

.p-spacer{
    padding: 2rem 0;
}

.c-spacer{
    margin-top: 2rem;
}

hr{
    padding: 0;
}


article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}


a, a:hover{
    text-decoration: none;
}

ul{
    margin: 0;
    padding: 0;
    list-style: none;
}


hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
}


a {
  color: var(--on-surface);
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: var(--primary);
  text-decoration: none;
}


.dropdown-menu{
  border: none;
  box-shadow: 0px 5px 15px 1px rgb(0 0 0 / 8%);
  border: 1px solid #f2f4f9;
  position: relative;
}

textarea{
    resize: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 400;
  line-height: 1.5;
  color: var(--primary);
}

h1,
.h1 {
  font-size: 2.5rem;
}

h2,
.h2 {
  font-size: 2rem;
}

h3,
.h3 {
  font-size: 1.75rem;
}

h4,
.h4 {
  font-size: 1.5rem;
}

h5,
.h5 {
  font-size: 1.25rem;
}

h6,
.h6 {
  font-size: 1rem;
}
/* 
.btn-primary {
  color: var(--on-primary);
  background-color: var(--primary);
  border-color: var(--primary);
}

.btn-primary:hover, .btn-primary:active, .btn-primary:visited{
  background-color: var(--secondary);
  border-color: var(--secondary-variant);
} */

.invalid-feedback{
    display: block;
}
.form-control, .form-control:focus, .form-control:hover, 
.form-control:active, .form-control:visited{
  outline: none;
  border: none;
  box-shadow: none;
}
.register-login{
    box-shadow: 0px 0px 5px rgb(0 0 0 / 15%);
}
.register-login  .form-control{
   border:1px solid #cccccc;
}


.btn-primary {
  color: var(--on-primary);
  background-color: var(--primary);
  border-color: var(--primary);
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.btn-primary:hover {
    color: var(--on-primary);
    background-color: var(--primary-light);
    border-color: var(--primary);
}

.btn-primary:focus,
.btn-primary.focus {
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0 rgba(118, 135, 232, 0.5);
}

.btn-primary.disabled,
.btn-primary:disabled {
    color: var(--on-primary);
    background-color: var(--primary);
    border-color: var(--primary);
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show>.btn-primary.dropdown-toggle {
    color: var(--on-primary);
    background-color: var(--primary);
    border-color: var(--primary);
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show>.btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(118, 135, 232, 0.5);
}


.btn-secondary {
  color: var(--on-secondary);
  background-color: var(--secondary);
  border-color: var(--secondary);
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.btn-secondary:hover {
  color: var(--on-secondary);
  background-color: var(--secondary-light);
  border-color: var(--secondary-light);
}

.btn-secondary:focus,
.btn-secondary.focus {
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0 rgba(212, 214, 216, 0.5);
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  color: var(--on-secondary);
  background-color: var(--secondary);
  border-color: var(--secondary);
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show>.btn-secondary.dropdown-toggle {
  color: var(--on-secondary);
  background-color: var(--secondary);
  border-color: var(--secondary);
}

.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show>.btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(212, 214, 216, 0.5);
}



/* outline button */
.btn-outline-primary {
    color: var(--primary);
    border-color: var(--primary);
  }
  
  .btn-outline-primary:hover {
    color: var(--on-primary);
    background-color: var(--primary);
    border-color: var(--primary);
  }
  
  .btn-outline-primary:focus,
  .btn-outline-primary.focus {
    box-shadow: 0 0 0 0 rgba(94, 114, 228, 0.5);
  }
  
  .btn-outline-primary.disabled,
  .btn-outline-primary:disabled {
    color: var(--on-primary);
    background-color: var(--primary);
  }
  
  .btn-outline-primary:not(:disabled):not(.disabled):active,
  .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show>.btn-outline-primary.dropdown-toggle {
    color: var(--on-primary);
    background-color: var(--primary);
    border-color: var(--primary);
  }
  
  .btn-outline-primary:not(:disabled):not(.disabled):active:focus,
  .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
  .show>.btn-outline-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(94, 114, 228, 0.5);
  }

  /* background primary   */


  .bg-primary{
      background: var(--primary) !important;
  }

  .bg-gray {
      background-color: #f2f2f2;
  }


/* ============================== 1. Start of Topbar ============================= */

.topbar-area{
    background-color: var(--primary);
    color: var(--on-primary);
    padding: 0.4rem 0;
}

.topbar{
    display: flex;
    align-items: center;
    flex-direction: column;
}

.topbar a{
    color: var(--on-primary);   
}

.topbar{
    font-size: 13px;
    color: var(--on-primary);
}

.topbar-right{
    margin-top: 10px;
}

.help-center .mx-1{
    font-weight: 500;
}

.topbar-menu>div{
    padding: auto 10px;
    cursor: pointer;
}

.currency-dropdown::before, .currency-dropdown::after{
    content: '';
    border-left: 1px solid var(--on-primary);
    margin-left: 15px;
    margin-right: 15px;
}

.topbar-menu .dropdown-menu{
    min-width: 170px;
}

.topbar-menu .dropdown-menu a{
    font-size: 14px;
    color: var(--on-surface)
}


.topbar-menu .dropdown-menu .dropdown-item i{
    color: #ccc;
}


.language-active, .currency-active{
    color: var(--green) !important;
}



/* ============================== 2. Start of Navbar ============================= */
.desktop-topbar{
    display: none;
}
.mobile-topbar{
    display: block;
}
.mobile-topbar .logo{
    padding-top: 0px;
}
.navbar-area{
    background: var(--surface);
}

.logo{
    text-align: center;
    padding-top: 1.5rem;
}

.product-search .css-1s2u09g-control {

	border-width: 0px;

}

.product-search .css-b62m3t-container {
	width:40%;
}

.navbar-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.searchbar{
    flex-grow: 1;
    padding: 1.5rem 0;
}

.navbar-menus{
    display: flex;
    text-align: center;
    justify-content: center;
}

.mobile-topbar .navbar-menus i {
    font-size: 15px;
}
.navbar-light .navbar-nav .nav-link {
    color: var(--white);
}

.searchbar .input-group{
    border: 1px solid #ccc;
    padding: 0.1rem 0.4rem;
    border-radius: 30px;
}

.searchbar .input-group-prepend {
	display: flex;
	align-items: center;
	margin: 5px 0px 5px 5px;
	border-right: 1px solid #ccc;
	padding-right: 10px;
    cursor: pointer;
}

.searchbar input{
    padding-right: 0;
}

.searchbar button{
    border: none;
    outline: none;
    background: none;
    padding: 5px 10px;
    color: var(--on-surface);
}

.navbar-menus a{
    padding: 10px;
    position: relative;
}

.mobile-topbar .navbar-menus a span {
    width: 15px;
    height: 15px;
    font-size: 10px;
}

.navbar-menus a span {
	position: absolute;
	top: 0;
	background: var(--success);
	color: var(--on-primary);
	font-weight: 500;
	border-radius: 50%;
	width: 20px;
	height: 20px;
	right: 0px;
}

.navbar-content .form-control{
    border: none;
}

.navbar-content .form-control:hover{
    border: none;
}

.navbar-menus i{
    font-size: 2em;
    color: var(--on-surface);
}

/* ============================== 3. Start of Menubar ============================= */
.menubar-area{
    background-color: var(--primary)
}

.level-1>a{
    display: block;
    padding: 0.5rem 0;
}

.level-1, .level-2{
    position: relative;
}


.level-1-dropdown>li>a{
    padding: 1rem 1.5rem;
    display: block;
}

.level-2>a>div{
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.level-1 li a:hover{
    background: #f8f8f8;
}

.level-2-dropdown{
    position: absolute;
    left: 100%;
    background: #fff;
    min-width: 600px;
    left: 100%;
    box-shadow: 0px 0px 10px #ddd;
    visibility: hidden;
    opacity: 0;
    top: 0;
    transform: translateY(100%);
    padding: 1.5rem;
	display: none;
}

.level-1-dropdown{
    position: absolute;
    min-width: 300px;
    background: #fff;
    box-shadow: 0px 0px 10px #ddd;
    opacity: 0;
    visibility: hidden;
    transform: translateY(30px);
	display: none;
    z-index: 99;
}



.level-2-dropdown .row{
    width: 100%;
}

.level-2-dropdown a{
    display: block;
    margin-top: 1rem;
}

/* ============================== 4. Start of Slider ============================= */


.slide-container .image-container img {
    height: 160px;
    width:100%;
}


.hero-swiper-container .swiper-pagination-bullet {
	background: #777777;
	opacity: 1;
	width: 20px;
	height: 3px;
	border-radius: 0;
}

.hero-swiper-container .swiper-pagination-bullet-active {
    background: #FFE600;
}

.swiper-pagination{
    bottom: 20px;
}


.swiper-pagination-bullet{
    margin-right: 10px;
    display:none;
}

.slider-content {
	position: absolute;
	top: 0;
	background: rgba(0,0,0,0.1);
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
}

.slider-content a{
    border-radius: 0;
    color: var(--primary);
    padding: 0.5rem 1.2rem;
}

.slider-content a:hover{
    color: var(--primary);
}

.slider-content h3{
    color: var(--secondary);
    font-size: 1.3rem;
}

.slider-content h1{
    font-size: 1.5rem;
	color: var(--on-primary);
}

.swiper-slide img{
	height: 100%;
}

.profile-button {
    background: rgb(99, 39, 120);
    box-shadow: none;
    border: none
}

/* ============================== 5. profile Section ============================= */
#userProfile{
    background-color: rgb(99, 139, 120) !important;
}
#userProfile .form-control{
    border: 1px solid #cccccc;
}
#userProfile .form-control:focus{
    border: 1px solid #BA68C8;
}
.profile-button:hover {
    background: #682773
}

.profile-button:focus {
    background: #682773;
    box-shadow: none
}

.profile-button:active {
    background: #682773;
    box-shadow: none
}

.back:hover {
    color: #682773;
    cursor: pointer
}

.labels {
    font-size: 11px
}

.add-experience:hover {
    background: #BA68C8;
    color: #fff;
    cursor: pointer;
    border: solid 1px #BA68C8
}

/* ============================== 5. Category Section ============================= */
.main-categories{
    background-color: var(--surface);
    height: 100%;
    padding: 1.5rem;
    box-shadow: 4px 4px 15px -5px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
}

.main-categories li a{
    display: block;
    padding: 0.4rem;
}

.category-product{
    border:1px solid #ccc
}
.category-products-section img{
    width: 100%;
}

.category-banner{
    position: relative;
}

.category-banner-content {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin-left: 3rem;
}

.category-banner-content h4{
    color: var(--on-primary)
}

.category-banner-content h1{
    font-size: 2.8rem;
    color: var(--secondary);
    font-weight: 600;
    font-style: italic;
}

.category-banner-content a{
    color: var(--primary);
    padding: 0.4rem 1.5rem;
    font-weight: 500;
    font-style: italic;
}

.slide-product {
    height:290px;
	margin: 0px 5px;
    border:1px solid #cccccc;
	
}

.cp-buy-now{
    background-color: var(--primary);
    display: block;
    width: 100%;
}

.cp-favorite {
    position: absolute;
    left: 8px;
    top: -30px;
    font-size: 1.5rem;
    color: var(--primary);
    transition: top 0.5s ease-in-out;
}


.buy-now {
	position: absolute;
	bottom: -9px;
	left: -4px;
	right: 0px;
	background: var(--primary);
	text-align: center;
	display: block;
	padding: 0px -1px;
	transition: bottom 0.5s ease-in-out;
	margin: 10px;
	width: 94%;
}

.shopping-cart-add-cart {
    display: none;
    height: 35px;
}

.category-products-section img {
	width: 100%;
    height: 200px;
}
.category-product:hover .cp-favorite{
    top: 0;
}

.category-product:hover .shopping-cart-add-cart{
    display: block;
}

.category-product:hover  .shopping-cart-product
{
    display: none;
}

/*==============================  sound section ======================================*/

.sound-area img{
    max-height: 200px;
    width:100%;
    overflow: hidden;
}

/* ============================== 6. Best Seller Section ============================= */

.best-seller-section .row>div{
    margin-bottom: 3rem;
}

.price-and-ratings{
    display: flex;
    justify-content: space-between;
}

.product-info h3{
    font-size: 1rem;
    margin-top: 0.7rem;
}

.product-price span:last-child, .product-rating i{
    color: var(--star-color)
}

.product-rating{
    display: flex;
    align-items: center;
}

.product-info a{
    display: block;
    padding: 0.2rem;
    margin-top: 0.5rem;
}

.product-image img{
    width: 100%;
    height: 175px;
}

/* ============================== 7. Service Section ============================= */

.our-services-area img{
    margin-bottom: 2rem;
}

.home-service-content{
    height: 100%;
}

.home-service-content p{
    line-height: 1.5rem;
}

.home-service-content a{
    margin-top: 2.5rem;
}


/* event solution ===============================*/

.event-type img{
    height: 210px;
}

.eventSolutionNav  .navbar-expand-lg .navbar-nav .nav-link {
    color: var(--white);
    padding-left: 5px;
    padding-right: 5px;
    font-size: 12px;
}
.eventSolutionNav .navbar-toggler{
    background-color: var(--white);
}

.eventSolutionNav .fas{
    color:gray
}



.event-solution .content-area{
    position: relative;
}
.event-solution  h2{
    font-weight: bold;
    border-bottom:  1px solid #cccccc;
}
.event-solution .content-area h4{
    position: absolute;
    bottom:0%;
    left: 0%;
    right: 0%;
    text-align: center;
    color: white;
    font-weight: 600;
}
/* ============================== 8. Featured Section ============================= */

.featured-product-section .row>div{
    margin-bottom: 3rem;
}



/* ============================== 9. Trending Section ============================= */

.trending-item-section .product-price{
    margin: 1rem 0;
}

.trending-item-section .row>div{
    margin-bottom: 1.5rem;
}


/*==================            buy sell product       ================*/
.buy-sell-banner .buy-product{
    background: url("../../images/Homepage/buyProduct.png");
    padding:30px  30px 30px 70px;
}

.buy-sell-banner .sell-product{
    background: url("../../images/Homepage/saleCrop.png");
    padding:50px  1000px 30px 70px;
}


/* ============================== 10. Subscriber Section ============================= */
.subscribe-area {
    background: url("../../images/common/subscribe.png");
    overflow: hidden;
}

.subscriber-content{
    color: #fff;
    padding: 2.5rem 0;
    z-index: 9;
    position: relative;
}

.subscriber-content h4{
    color: var(--secondary);
    margin-bottom: 1rem;
    text-shadow: 0px 0px 10px #333;
}

.subscriber-form input {
    border-radius: 4px;
    padding: 0.6rem;
}

.subscriber-form input:focus, .subscriber-form input:hover, .subscriber-form input:visited {
    border-radius: 4px;
    border: none;
    outline: none;
    box-shadow: none;
}

.subscriber-form p{
    margin-bottom: 0.4rem;
}

.subscriber-btn p{
    display: none;
}

.subscriber-btn button{
    padding: 0.6rem 1rem;
    background: var(--secondary);
    border: none;
    color: var(--primary);
    border-radius: 4px;
    margin-top: 1rem;
}

.subscriber-content::after {
    content: '';
    width: 8rem;
    height: 170%;
    background: var(--secondary);
    position: absolute;
    top: -71px;
    right: 0;
    transform: rotate(-30deg);
    z-index: -1;
}

/* ============================== 11. Footer Menu Section ============================= */

.footer-mainmenu li a, .footer-important-link li a{
    padding: 0.4rem 0;
    display: block;
}

.address-section address{
    margin: 2rem 0;
    line-height: 1.3rem;
}

.address-section>span{
    font-weight: 600;
}

.footer-message input[type='email'], .footer-message textarea{
    box-shadow: 4px 4px 15px -5px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
}

.footer-message textarea{
    padding: 1rem;
    max-height: 150px;
    width: 100%;
}

.footer-message textarea, .footer-message textarea:focus, .footer-message textarea:active, .footer-message textarea:visited{
    border: none;
    outline: none;
}

.footer-message button{
    border: none;
    outline: none;
    box-shadow: 4px 4px 15px -5px rgba(0, 0, 0, 0.25);
    padding: 0.5rem 1rem;
    background: #fff;
    border-radius: 6px;
    color: #555;
}


/* ============================== 12. Copyright Section ============================= */

.copyright{
    background: #222222;
}

.copyright p{
    text-align: center;
    margin: 0;
    padding: 0.7rem;
    color: var(--on-primary);
    font-weight: 400;
    font-size: 12px;
}
/* ========================================================================================= */

/* ============================== Service Page Section ============================= */

.service-title-area{
    background: var(--surface);
    background: url("../../images/services/top-banner.png") no-repeat;
    background-size: cover;
}

.why-choose ul li{
    background: #F1F2F2;
    margin-bottom: 10px;
   
}



.why-choose ul li ul li{
  list-style:disc;
  margin-left:50px;
  margin-bottom: 0px;
}

.service-area{
    background-color: #F1F2F2;
}
.service-area .service-box{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }

.service-area .service{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 5px 10px;
    flex-basis: 24%;
    margin-bottom: 20px;
    background-color: white;
}

/*=================================== logistic page  ======================================= */

.logistic-bg-title{
    background: var(--surface);
    background: url("../../images/logistics/banner.png") no-repeat;
    background-size: cover;
    height: 200px;
}

.logistic-service-area .logistic-service{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }

.logistic-service-area .service-box{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 10px 20px;
    flex-basis: 30%;
    margin-bottom: 20px;
    background-color: #F1F2F2;
}


/*=============================== Training page ============================================*/

.training-title-bg{
    background: url("../../images/training/banner.png") no-repeat;
    background-size: cover;
    height: 200px;
}



/*================================= warehouse page ==================================*/

.warehouse-bg-title{
    background: url("../../images/warehouse/banner.png") no-repeat;
    background-size: cover;
    height: 200px;
}

.platform {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
}

.platform .card{
    flex-basis: 24%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 10px;
    margin-bottom: 20px;
    background-color: #F1F2F2;
}



/* ======================================  Shop Page ========================================== */
.new-arrival-section .row>div{
    margin-bottom: 2rem;
}

/* ======================================  Product Details Page ========================================== */
.breadcrumb-item+.breadcrumb-item::before {
    content: ">";
}

.product-breadcrumb .breadcrumb{
    background: none;
}

.product-breadcrumb li{
    font-weight: 600;
}

.product-breadcrumb .active{
    color: #999;
}

.details-block{
    background: var(--surface);
    border-radius: 6px;
    padding: 1rem;
    box-shadow: 4px 4px 15px -5px rgba(0, 0, 0, 0.25);
}

.product-details h4{
    color: var(--star-color)
}

.product-details p{
    max-width: 85%;
}

.brand span:last-child{
    color: var(--star-color)
}

.share-media{
    display: flex;
    align-items: center;
}

.share-media a{
    display: block;
    margin-right: 1rem;
    border: 1px solid #ccc;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    text-align: center;
    padding: 4px;
}

/* ======================================  Contact Page ========================================== */

/*************** Contact Area CSS ***************/
.title-with-bg-area{
    background: url("../../images/about-us-banner.png") no-repeat;
    background-size: cover;
}

.title-with-bg{
    text-align: center;
    padding: 4rem 0;
}

.title-with-bg h4{
    position: relative;
    color: var(--secondary);
    padding-bottom: 5px;
}

.title-with-bg p{
    color: var(--secondary);
}

.title-with-bg h6{
    color: var(--secondary)
}

.title-with-bg h4::after {
    content: '';
	width: 300px;
	height: 1px;
	background: var(--secondary);
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
}

.contact-area {
    background: #f2f2f2;
}



.contact-form, .address-content{
    box-shadow: 0px 0px 5px rgba(0,0,0,0.15);
    background: #fff;
    padding: 20px;
    border-radius: 6px;
}


.contact-form button{
    width: 100%;
    margin-bottom: 10px;
}

.address-content {
    margin-top: 20px;
    height: 100%;
}

.address-info{
    padding-bottom: 20px;
}

.address-info p{
    display: flex;
    margin-bottom: 15px;
}


.contact-box-social i{
    font-size: 14px !important;
}

.social-icons{
    display: flex;
}

.social-icons a {
    border: 1px solid var(--on-primary);
    padding: 10px;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
}

.social-icons a i{
    font-size: 10px;
}

.social-icons a:hover{
    background: var(--on-primary);
    color: var(--on-secondary);
}


.field-text, .field-text:hover, .field-text:active, .field-text:visited, .field-text:focus {
    border-bottom: 1px solid #ccc;
    border-top: none;
    border-right: none;
    border-left: none;
    border-radius: 0;
    box-shadow: none;
    padding: 0;
}


.field-textarea, .field-textarea:hover, .field-textarea:active, .field-textarea:visited, .field-textarea:focus {
    border: 1px solid #ccc;
    border-radius: 0;
    box-shadow: none;
    border-radius: 6px;
}

.fa, .far, .fas {
    line-height: 1.5rem;
}

/*=============== blog post  =============================*/
.blog-post-craete input, .blog-post-craete textarea{
    border: 1px solid #c0b9b9
}

.blog-post-craete input:hover, .blog-post-craete textarea:hover{
    border: 1px solid #c0b9b9
}

/* ======================================  About Page ========================================== */

.about-eventincu-area{
    background: var(--surface);
}

.vision-message-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.vision-message-content p{
    line-height: 1.5rem;
}

.know-about-content p{
    line-height: 1.5rem;
    margin-bottom: 1rem;
}

.about-end-area{
    background: #fff;
}

.about-end-content li{
    display: flex;
    margin-bottom: 1.5rem;
}

/* cart page details */

.cart-details p, .wishlist p{
   background-color: var(--gray-dark);
   color:var(--white);
   padding:10px 0px;
}
.cart-details input[type="number"]
{
    width:20%;
    background-color:var(--white);
    border:1px solid #cccccc;
}

.coupon-area{
    margin:50px 0px;
}
.coupon-area .coupon-cart{
   
    justify-content: space-between;
}

.coupon-area .coupon-code input{
    padding:5px 5px;
    border: 1px solid #555555;
}
.coupon-area .coupon-code button{
        padding: 5px 15px;
        background: var(--primary);
        color: var(--white);
        text-transform: uppercase;
        text-decoration: none;
        margin-left:-10px;
        border: 1px solid #555555;
}


.update-cart button{
    padding: 5px 15px;
    background: var(--primary);
    color: var(--white);
    text-transform: uppercase;
    text-decoration: none;
    border: 1px solid #555555;
}
.cart-total, .btn-area{
    min-width:240px;
    float: right;
}
.cart-total{

  padding:10px;
  background-color: #e5e5e5;
  border:1px solid #cccccc;
}

.cart-total h6{
    border-bottom:1px solid #cccccc;
}

.justify-space-btwn{
  justify-content: space-between;
}
.cart-total .total{
    border-top:1px solid #cccccc;
    margin-top:10px;
}
.btn-area{
    margin-top: 10px;
}

.custom-btn{
    padding: 5px 15px;
    text-transform: uppercase;
    text-decoration: none;
    border: 1px solid #555555;
}


/*  term of use page  */

.term-top{
    background:url('../../images/about-us-banner.png');
    padding-top:40px;
    padding-bottom:40px;
}

.term-article-area ul {
    list-style: auto;
    font-family: sans-serif;
    margin-left: 40px;
}

.term-article-area h3{
     font-family: sans-serif;
}
/*=========================== checkout  ===========================*/
.checkout-area{
    background-color: var(--white);
}
.checkout-area  h5{
    background-color: var(--star-color);
    padding:5px 0px;
    color:var(--white);
    margin:10px 0px;
  }
  .checkout-area input[type="text"], .checkout-area select{
      width: 100%;
      padding: 5px;
      margin: 8px 0;
      border: 1px solid #ced4da;
      outline: none;
  }
  .form-check-input {
    margin-right: -1.25rem;
}
  
 .border-bottom-c{
     border-bottom:1px solid #cccccc;
 }
 .border-top-c{
    border-top:1px solid #cccccc;
}

 .order-details{
     background-color:#e5e5e5;
     padding:30px
 }
 /* numering coding */

 .numbering-coding-bg-title{
    background: url("../../images/NumberCoding/banner.png") no-repeat;
    background-size: cover;
    height: 200px;
}

 .numbering-roles ul li{
      padding:5px 5px;
      background-color: #F1F2F2;
      margin: 10px 0px;
 }



/*  registration page  */
.login-section {
    background:#ccc;
    padding: 30px 0;
}
.login-box {
    background: white;
    max-width: 550px;
    margin: 0 auto;
    padding: 30px;
}
.login-box input {
    width: 100%;
    padding: 0px;
    margin: 8px 0;
    border: 1px solid #ced4da;
}
.login-box textarea {
    width: 100%;
}
.login-box input, textarea {
    display: block;
    width: 100%;
    padding: 5px;
    border: 1px solid #ccc;
    outline:none;
}


.checkbox-left span input {
    display: inline;
    width: inherit;
    margin-right: 10px;
}

.btn-custom.login {
    display: block;
    width: 100%;
    text-align: center;
    margin-top: 10px;
}
.btn-custom {
    padding: 8px 15px;
    background: var(--primary);
    color: var(--white);
    text-transform: uppercase;
    text-decoration: none;
    display: inline-block;
    border-radius: 3px;
    border: 1px solid var(--sc);
    margin-bottom: 10px;
}


.blog{
   background-color: #E4F2F3;
   font-family: sans-serif;
}

.blog-content{
    padding:10px;
}

/*===============   client panel */

.profile-update input, .profile-update textarea{
    border : 1px solid #cccccc
}

.request{
    background-image: url("../../images/form.jpg");
}



/*=============================   laboratory page  ============================   */
.laboratory-bg{
  background-image: url('../../images/laboratory/banner.png');
  background-position: center;
  background-size: cover;
  height: 200px;
}             




/* ========================================================================================= */
@media only screen and (min-width: 200px) {
.level-1-dropdown{
        display: block;
}

.level-2-dropdown{
    display: block;
}
    
    .level-2:hover .level-2-dropdown, .level-1:hover .level-1-dropdown{
        opacity: 1;
        visibility: visible;
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
        -o-transition: -o-transform 0.3s,opacity 0.3s;
        -ms-transition: -ms-transform 0.3s,opacity 0.3s;
        -moz-transition: -moz-transform 0.3s,opacity 0.3s;
        -webkit-transition: -webkit-transform 0.3s,opacity 0.3s;
    }
}
@media only screen and (min-width: 600px) {
    .slide-product {
        height: 315px;
    }
    .desktop-topbar{
        display: block;
    }
    .mobile-topbar{
        display: none;
    }
    .slide-container .image-container img {
        height: 200px;
    }
    .service-name{
        top:150px;
    }
    
    /*  Slider */
	.swiper-slide img{
        display: block;
        height: 100%;
    }
    
    .slider-content h1{
        color: var(--on-primary);
        font-size: 1.8rem;
        margin-bottom: 1rem;
    }


    
}


@media only screen and (min-width: 768px) {
    
    html{
        font-size: 100%;
    }

    /* Topbar */
    .topbar-right{
        margin-top: 0;
    }

    .topbar{
        flex-direction: row;
        justify-content: space-between;
    }

    /* Navbar */
    .navbar-content{
        flex-direction: row;
    }    

    .logo,.searchbar{
        padding: 0;
    }

    .searchbar{
        padding: 1.3rem 2.5rem;
    }
    /* profile  */
    .modal-dialog {
        max-width: 750px;
    }
	
	/*  Slider */
	
	.hero-swiper-container{
        height: 350px;
    }

    .slider-content h1{
        font-size: 2rem;
        margin-bottom: 1.5rem;
    }

    /* Subscriber area */
    .subscriber-form{
        display: flex;
    }

    .subscriber-btn p{
        display: block;
    }

    .subscriber-btn button{
        margin-top: 0;
    }

    .subscriber-btn{
        margin-left: 2rem;
    }

    .subscriber-input{
        flex-grow: 1;
    }

    .address-content{
        margin-top: 0;
    }
}

@media only screen and (min-width: 992px) {

	/*  Menubar */
	
	
	/*  Slider */
    .hero-swiper-container{
        height: 400px;
    }

    .slider-content h1{
        font-size: 2.5rem;
        margin-bottom: 2rem;
    }

    .slider-content h3{
        font-size: 1.5rem;
    }

    .contact-form button {
        width: auto;
    }

}


@media only screen and (min-width: 1200px) {

    .hero-swiper-container{
        height: 450px;
    }

}